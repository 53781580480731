import React, {Component} from 'react';
import ModuleDetails from './ModuleDetails'

class ModulePage extends Component{

  state = {
    ModuleDetail: []
  };

  componentWillMount (){
    const nid = window.location.pathname.split('/').pop();
    const url = "https://drupal-modules-backend.eric-heydrich.de/dmb-modules-detail/"+ nid +"?format=json";

    fetch(url)
        .then(result => result.json())
        .then(result => {
          this.setState({
            ModuleDetail: result
          })
        });
  }

  render() {
    const { ModuleDetail } = this.state;

    return (
      <div className="moduleDetails">
        <ModuleDetails details={ModuleDetail}/>
      </div>
    );
  }
}

export default ModulePage;
import React, {Component} from 'react';
import Module from "./Module";

class ModuleContainer extends Component {

  constructor(props){
    super(props);
    this.state = {
      ModuleValue: []
    };
  }


  componentWillMount (){
    const url = "https://drupal-modules-backend.eric-heydrich.de/dmb-modules?format=json";

    fetch(url)
        .then(result => result.json())
        .then(result => {
          this.setState({
            ModuleValue: result
          });
          localStorage.setItem('moduleData',JSON.stringify(result));
        });
  }

  render() {
    const { ModuleValue } = this.state;

    return (
        <div className="moduleContainer">
          <Module characterData={ModuleValue}/>
        </div>
    );
  }
}

export default ModuleContainer;
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/header.css';

const Header = () => (
    <header className={'page-header'}>
      <div className={'page-header__inner'}>
        <p><Link to='/'>DrupalModules</Link></p>
      </div>
    </header>
);

export default Header

import React, {Component} from 'react';
import Module from "./Module";

class ModuleContainerOffline extends Component {

  constructor(props){
    super(props);
    this.state = {
      ModuleValue: []
    };
  }

  componentWillMount (){
    const lsData = localStorage.getItem('moduleData') ;
    this.setState({
      ModuleValue: JSON.parse(lsData)
    });
  }

  render() {
    const { ModuleValue } = this.state;

    return (
        <div className="moduleContainer">
          <Module characterData={ModuleValue}/>
        </div>
    );
  }
}

export default ModuleContainerOffline;
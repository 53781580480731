import React from 'react';

const DetailsWrapper = props => {
  const rows = props.details.map((row,index) => {
    return (
        <div>
          <strong className={'module-card-' + row.nid + '__title'}>{row.title}</strong>
          <p>#{row.field_kategorie}</p>
          <p>{row.field_modulbeschreibung}</p>
        </div>
    );
  });
  return <div className={'inner-wrapper'}>{rows}</div>;
};

class ModuleDetails extends React.Component {
  render() {
    const {details} = this.props;

    return(
        <div className="module">
          <DetailsWrapper details={details}/>
        </div>
    );
  }
}

export default ModuleDetails;
import React from 'react';
import copy from 'copy-to-clipboard';
import { Link } from 'react-router-dom';
import shield from '../images/icons8-shield-96.png';
import important from '../images/icons8-star-filled-filled-96.png';
import testing from '../images/icons8-test-tube-96.png';
import composer from '../images/composer.svg';
import download from '../images/icons8-downloading-updates-96.png';
import '../css/card.css'

function composerCopy(composer) {
    if (composer.length === 0){
        composer = 'No value available!'
    }
    copy(composer);
}

const Wrapper = props => {
  const rows = props.characterData.map((row,index) => {

    let content = [];
    if(row.field_flags.includes('secure')){
      content.push(<p className={'secure'}><img src={shield} alt={'Shield for marking secure module.'} title={'Module is covered by security team.'} width={40} height={40}/></p>);
    }
    if(row.field_flags.includes('important')){
      content.push(<p className={'important'}><img src={important} alt={'Star for marking important module.'} title={'Important module.'} width={40} height={40}/></p>);
    }
    if(row.field_flags.includes('testing')){
      content.push(<p className={'testing'}><img src={testing} alt={'Tube for marking module necessary to test.'} title={'Module should be tested.'} width={40} height={40}/></p>);
    }

    return (
      <div className={'module-card module-card__' + row.nid} key={index} >
          <header className={'module-card__header ' + row.field_flags}>
            <div className={'module-card__header-left'}>
              {content}
            </div>
            <div className={'module-card__header-right'}>
              <span className={'module-card__composer'} data-composer={row.field_composer} onClick={() => composerCopy(row.field_composer)}><img src={composer} alt={'Install command for composer.'} title={'Composer Installation Command. Click to copy'} width={40} height={40}/></span>
              <a className={'module-card__link'} href={row.field_link_zum_modul} target={'_blank'} title={'Download the module ' + row.title} rel={'nofollow noopener noreferrer'}><img src={download} alt={'Download the module ' + row.title} width={40} height={40}/></a>
            </div>
          </header>

          <main className={'module-card__main'}>
              {<h3 className={'module-card__title'}><Link to={{ pathname: '/module/' + row.nid }}>{row.title}</Link></h3>}
              <p className={'module-card__category'}>#{row.field_kategorie}</p>
          </main>

      </div>
    )
  });
  return <div className={'inner-wrapper'}>{rows}</div>;
};

class Module extends React.Component {
  render() {
    const {characterData} = this.props;

    return(
        <div className="module">
          <Wrapper characterData={characterData}/>
        </div>
    );
  }
}

export default Module;
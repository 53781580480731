import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {Offline, Online } from 'react-detect-offline';

import ModuleContainer from "./ModuleContainer";
import ModuleContainerOffline from "./ModuleContainerOffline";
import ModulePage from "./ModulePage";
import Header from './Header';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
        <div>
          <Router>
            <div>
              <Header/>
              <Online>
                <div className="App">
                  <Switch/>
                  <Route path="/" exact component={ModuleContainer} />
                  <Route path="/module" component={ModulePage} />
                  <Switch/>
                </div>
              </Online>
              <Offline>
                <div className="App">
                  <div className="warning">Ihr Gerät ist aktuell nicht mit dem Internet verbunden. Es werden Daten aus dem Zwischenspeicher verwendet.</div>
                  <Switch/>
                  <Route path="/" exact component={ModuleContainerOffline} />
                  <Route path="/module" component={ModulePage} />
                  <Switch/>
                </div>
              </Offline>
              <Footer/>
            </div>
          </Router>
        </div>
    );
  }
}

export default App;

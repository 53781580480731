import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

// The Header creates links that can be used to navigate
// between routes.
const Footer = () => (
    <footer className={'page-footer'}><Link to='/'>Nach oben</Link></footer>
);

export default Footer

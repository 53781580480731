import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import App from './components/App';
import './css/colors.css';
import './index.css';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <BrowserRouter>
    <App/>
  </BrowserRouter>
  ), document.getElementById('root')
);

serviceWorker.register();